/*
Author: Viktor (victor318x@gmail.com)
utils.js (c) 2021
Desc: description
Created:  6/1/2021, 11:55:31 AM
Modified: 6/1/2021, 11:56:28 AM
*/
import { Fetch } from '@ekuaibao/fetch';

export const Domain = window.SYNC_TEMPLATE_ORIGIN || Fetch.fixOrigin(location.origin);

/**
 * detect client browser
 */
export const detectClientBrowser = () => {
  const _userAgent = navigator.userAgent;
  let _broMark = '';
  if (/DingTalk/gi.test(_userAgent)) {
    // 判断是否是钉钉客户端
    _broMark = 'DingTalk';
    return _broMark;
  } else if (/wxWork/gi.test(_userAgent)) {
    // 判断是否是微信客户端
    _broMark = 'wxWork';
    return _broMark;
  }

  // 如果是手机浏览器
  if (/Mobile/gi.test(_userAgent)) {
    if (/MicroMessenger/gi.test(_userAgent)) {
      _broMark = 'wx';
    } else if (/ycBrowser/gi.test(_userAgent)) {
      _broMark = 'yx';
    } else {
      _broMark = 'browser';
    }
  }
  return _broMark;
};
