import { app } from '@ekuaibao/whispered';
import { Fetch } from '@ekuaibao/fetch';
/**
 * Created by LinK on 2017/6/5.
 */
import { app as api } from '@ekuaibao/whispered';
// import { fnCheckPayerInfo } from '../../components/utils/fnCheckPayerInfo'
import { switchcorporationForLog } from './corporation-info-fetch-util';
import * as actions from './corporation-info-action';
import CompanyInitTemplateManagerPlugins from './company-init-template-manage';

const dispatchHelpers = (name, thenFn = (d) => d) => async (...theArgs) => {
  const actions = await import('./corporation-info-action');
  const fn = actions[name];
  return api.dispatch(fn(...theArgs)).then(thenFn);
};

export default [
  {
    id: '@corporation-info',
    reducer: () => import('./corporation-info-reducer'),
    path: '/corporation-info',
    ref: '/',
    onload: () => import('./corporation-info-view'),
    // onbefore: async (app, next) => {
    //   await app.dataLoader('@common.permissions').load()
    //   next()
    // },
    checkInvoiceSettingInfo: async (_) => {
      const { fnCheckPayerInfo } = app.require('@components/utils/fnCheckPayerInfo');
      try {
        const user = await api.dataLoader('@common.userinfo').load();
        const payerInfo = await api.dataLoader('@common.payerInfo').load();
        return fnCheckPayerInfo(user.data, payerInfo);
      } catch (e) {
        console.log(e);
      }
    },
    'get:corporation:and:count': dispatchHelpers('getCorporationsAndCount'),
    switchcorporationForLog: (corpId) => {
      return switchcorporationForLog(corpId);
    },
    getCorpInfo: () => {
      return api.dispatch(actions.getCorpInfo());
    },
    getInitializationStatue: () => {
      return api.dispatch(actions.getInitializationStatue());
    },
    getCaptcha: (data) => {
      return actions.getCaptcha(data);
    },
    initialize: async ({ staffId, corporationName }) => {
      const res = await api.dispatch(
        actions.getSteps({
          staffId, //员工ID
          corpId: Fetch.ekbCorpId,
          templateId: null,
        }),
      );
      const value = res?.value || {};
      const { nodes, taskId, templateId, status } = value;
      const isContinue =
        Array.isArray(nodes) && nodes?.length > 0 && taskId !== nodes?.slice(-1)[0]?.id;
      // 打开系统初始化面板
      api.open('@corporation-info:InitializeModal', {
        corporationName,
        isContinue: status ? status === 'FAIL' : isContinue,
        isNormal: Array.isArray(nodes),
        staffId,
        templateId,
      });
    },
  },
  {
    point: '@@layers',
    prefix: '@corporation-info',
    onload: () => import('./layers'),
  },
  ...CompanyInitTemplateManagerPlugins,
];
