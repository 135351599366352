/**
 * Created by LinK on 2017/6/9.
 */

import key from './key';
import { Resource } from '@ekuaibao/fetch';
import { Domain } from './utils';

const payerinfo = new Resource('/api/v1/corporationset/payerinfo');
const corpInfo = new Resource('/api/v1/organization/corporations/info');
const initialization = new Resource('/api/v1/initialization');
const organization = new Resource('/api/v1/organization');
const appUsers = new Resource('/api/app/account/users');
const invoiceChecker = new Resource('/api/v2/invoice');
const corpNature = new Resource('/api/v1/corporationset/corpNature');
const syncTemple = new Resource(`${Domain}/api/v1/step`); // 同步模板
const shareTemple = new Resource(`/api/v1/corpTemplate/share`); // 分享模板  
const modualData = new Resource(`/api/v1/step/group`); // 功能模块列表 
const clusterCorporations = new Resource(`/api/v1/group/client/permission/corporations`);

// 获取企业信息

export function getCorpInfo() {
  const data = {
    sensitiveWords: true, //敏感词
  };
  return {
    type: key.GET_CORP_INFO,
    payload: corpInfo.GET('', data),
  };
}

export function getCorpNature() {
  return {
    type: key.GET_CORP_NATURE,
    payload: corpNature.GET(),
  };
}

// 修改企业基本信息
export function updateCorpInfo(param) {
  return {
    type: key.UPDATE_CORP_INFO,
    payload: corpInfo.PUT(param),
  };
}

// 新建/修改企业开票信息
export function updatePayerInfo(param) {
  return {
    type: key.UPDATE_PAYER_INFO,
    payload: payerinfo.PUT(param),
  };
}

// 删除企业开票信息
export function deletePayerInfo(param) {
  return {
    type: key.DELETE_PAYER_INFO,
    payload: payerinfo.DELETE('/$id', param),
  };
}

// 获取企业开票信息的二维码
export function getPayerInfoQrCode(id) {
  let param = { id };
  return {
    type: key.GET_PAYER_INFO_QRCODE,
    payload: payerinfo.GET('/$id/qrcode', param),
  };
}

export function verifivavtionCode(param) {
  return {
    type: key.VERIFIVATION_CAPTCHACODE,
    payload: initialization.POST('/initialize', param),
  };
}

export function getCaptcha(param) {
  return {
    type: key.GET_CAPTCHACODE,
    payload: initialization.POST('/captcha', param),
  };
}

export function getInitializationStatue() {
  return {
    type: key.GET_INITIALIZATION,
    payload: initialization.GET('/permission'),
  };
}

export function getCorporationsAndCount(data) {
  return {
    type: key.CORPORATIONS_COUNT,
    payload: organization.GET('/corporations/andCount', data),
  };
}

export function getClusterCorporations() {
  return {
    type: key.CLUSTER_CORPORATIONS,
    payload: clusterCorporations.GET(),
  };
}

export function deleteCorporation() {
  return appUsers.PUT('/dissolutionCorp');
}

//获取/设置发票导入规则
export function getInvoiceImportRules() {
  return {
    type: key.GET_INVOICE_IMPORT_RULES,
    payload: invoiceChecker.GET('/checker'),
  };
}

export function saveInvoiceImportRules(checkCorpPayerNameAndNo) {
  return {
    type: key.SAVE_INVOICE_IMPORT_RULES,
    payload: invoiceChecker.PUT('/checker', { checkCorpPayerNameAndNo }),
  };
}

//获取步骤
export function getSteps(params) {
  return {
    type: key.GET_STEPS,
    payload: syncTemple.POST('/stepList', params),
  };
}

// 开始同步模板
export function beginSync(params) {
  return {
    type: key.BEGIN_SYNC,
    payload: syncTemple.POST('/stepStart', params),
  };
}

// 开始同步模板
export function giveUp(params) {
  return {
    type: key.GIVEUP,
    payload: syncTemple.PUT('/giveUp', params),
  };
}

// 分享模板
export function goShareTemple(params) {
  return {
    type: key.SHARE_TEMPLE,
    payload: shareTemple.POST('/',params),
  };
}

// 获取全部模板数据
export function getModualData() {
  return {
    type: key.GET_MODUAL_DATA,
    payload: modualData.GET('/list'),
  };
}
