export default [
  {
    ref: '/init-template-manage',
    path: '/my-template',
    onload: () => import('./page/my-company-init-template')
  },
  {
    ref: '/init-template-manage',
    path: '/share-template',
    onload: () => import('./page/my-company-share-template') 
  },
  {
    id: '@corporation-init-template',
    path: '/init-template-manage',
    exact: true,
    dependencies: ['@common'],
    onload: () => import('./layout/company-init-template-dashboard'),
  },
  {
    point: '@@layers',
    prefix: '@corporation-init-template',
    onload: () => [
      {
        key: 'edit',
        getComponent: () => import('./modal/template-edit-modal'),
        className: 'template-edit-modal-layer',
        width: 600,
        closable: false,
        maskClosable: false,
      },
    ],
  },
];
