/**
 * Created by LinK on 2017/6/5.
 */

export const ID = '@corporation-info';

export default {
  ID: ID,
  GET_PAYER_INFO: `${ID}/GET_PAYER_INFO`,
  UPDATE_CORP_INFO: `${ID}/UPDATE_CORP_INFO`,
  GET_CORP_INFO: `${ID}/GET_CORP_INFO`,
  GET_CORP_NATURE: `{ID}/GET_CORP_NATURE`,
  UPDATE_PAYER_INFO: `${ID}/UPDATE_PAYER_INFO`,
  DELETE_PAYER_INFO: `${ID}/DELETE_PAYER_INFO`,
  GET_PAYER_INFO_QRCODE: `${ID}/GET_PAYER_INFO_QRCODE`,
  VERIFIVATION_CAPTCHACODE: `${ID}/VERIFIVATION_CAPTCHCODE`,
  GET_CAPTCHACODE: `${ID}/GET_CAPTCHACODE`,
  GET_INITIALIZATION: `${ID}/GET_INITIALIZATION`,
  CORPORATIONS_COUNT: `${ID}/CORPORATIONS_COUNT`,
  GET_INVOICE_IMPORT_RULES: `${ID}/GET_INVOICE_IMPORT_RULES`,
  SAVE_INVOICE_IMPORT_RULES: `${ID}/SAVE_INVOICE_IMPORT_RULES`,
  GET_STEPS: `${ID}/GET_STEPS`,
  BEGIN_SYNC: `${ID}/BEGIN_SYNC`,
  GIVEUP: `${ID}/GIVEUP`,
  SHARE_TEMPLE: `${ID}/SHARE_TEMPLE`,
  GET_MODUAL_DATA: `${ID}/GET_MODUAL_DATA`,
  CLUSTER_CORPORATIONS: `${ID}/CLUSTER_CORPORATIONS`,
};
