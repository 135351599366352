/**
 * Created by LinK on 2018/1/16.
 */
import { Resource } from '@ekuaibao/fetch'

const payerinfo = new Resource('/api/v1/corporationset/payerinfo')

const switchcorporation = new Resource('/api/v1/organization/staffs/switchcorporation')

// 查询企业开票信息
export function searchPayerInfo(content) {
  const data = {
    join: 'visibility.staffs,staffs,/v1/organization/staffs',
    join$1: 'visibility.departments,departments,/v1/organization/departments',
    join$2: 'visibility.roles,roles,/v1/organization/roles'
  }
  return payerinfo.PUT('/search', { content }, data)
}

// 记录进入和切换企业行为
export function switchcorporationForLog(corpId) {
  return switchcorporation.POST('/$corpId', null, { corpId })
}
